@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.App {
  text-align: center;
}

* {
  margin: 0px;
  padding: 0px;
}

body {
  background-color: #fdfdfdb3;
}

.deploy-main-card{
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 10px;
}

body h2 {
font-family: sans-serif;
}

.container {
  display: flex;
}

.bck-img {
  flex: 1;
  max-width: 70%;
}

.left-side {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh; /* Full page height */
}

.left-side .logo {
  width: 35rem;
  margin: auto;
  vertical-align: middle;
  position: relative;
  transform: translate(0px, 40vh);
}

.login-form {
  max-width: 30%;
  margin: auto;
}


@media (max-width: 767px) {
  
  .container {
    flex-direction: column;
  }
  .bck-img{
    max-width: 100%;
  }

  .left-side{
border-bottom-left-radius: 10px;
border-bottom-right-radius: 10px;
box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  }


  .left-side .logo {
    width: 50rem;
    margin: auto;
    vertical-align: middle;
    position: relative;
    transform: translate(0px, 0vh);
    padding: 60px;
    
  }

  .login-form {
    max-width: 100%;
    margin: auto;
  }


}




p{
  font-family: Arial, Helvetica, sans-serif;
}

/* Hide the scrollbar track */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the thumb */
}

/* Style the scrollbar track on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

#confetti {
  overflow-y: hidden;
  overflow-x: hidden;
  width: 100%;
  margin: 0;
  height: 100%;
  position: absolute;
  top: 0;
}